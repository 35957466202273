$('#menu li').mouseenter(function(event) {
	$(this).removeClass('mouse-leave');
	$(this).addClass('mouse-enter');
});

$('#menu li').mouseleave(function(event) {
	$(this).removeClass('mouse-enter');
	$(this).addClass('mouse-leave');
});

$(document).ready(function(){
	$('#menu').addClass('load');
})
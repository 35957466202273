$(document).ready(function() {
	$(this).scroll(function(event) {
		var t  = $(this)
		, 	st = t.scrollTop()
		,	h  = $(window).innerHeight() + st
		, 	x  = st
		,   x  = (x * -1)
		,	x  = (x * .2);

		$('.wrapper').css({
			'background-position' : 'center ' + x + 'px'
		});

		$('[data-animate]').each(function(index, el) {
			console.log(st, h);
			if($(el).offset().top < h){
				$(el).addClass('animated');
			}
		});
	});
});
(function() {
    var politica = localStorage.getItem('politica');

    if(politica) {
        $('.politica__privacidade').remove();
    }

    $('.politica__privacidade .btn').click(function(e) {
        e.preventDefault();

        localStorage.setItem('politica', JSON.stringify({
            termos_aceito: true 
        })),

        $('.politica__privacidade').remove();
    })
})();